<template>
  <div class="w-full height-container-mapbox">
    <div id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import etudeCommerciale from '@/mixins/etudeCommerciale'
import helpers from '@/mixins/helpers'

export default {
  data() {
    return {
      allPoints: [],
    }
  },
  computed: {
    ...mapGetters({
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
      markers_pc: 'etudeCommerciale/get_markers_pc',
      markers_dvf: 'etudeCommerciale/get_markers_dvf',
    }),
  },
  props: {
    ventes_proximite: {
      type: Array,
      require: true,
    },
    permis_construire: {
      type: Array,
      require: true,
    },
  },
  mixins: [Mapbox, parcelle],
  mounted() {
    this.loadMap()
      ? this.loadDataToMap()
      : this.map.on('load', this.loadDataToMap)
  },

  methods: {
    getAllPoints(all_coordinates, is_reverse_coordinates) {
      return _.map(_.union(...all_coordinates), (item) => {
        let coordinates = item.location.coordinates
        return this.$turf.point(
          !is_reverse_coordinates
            ? coordinates
            : [coordinates[1], coordinates[0]],
        )
      })
    },
    loadDataToMap() {
      let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
      var collection = this.$turf.featureCollection([
        ...this.getAllPoints(
          [
            ...(this.permis_construire && this.permis_construire.length
              ? this.permis_construire
              : []),
          ],
          true,
        ),
        ...this.getAllPoints([
          ...(this.ventes_proximite && this.ventes_proximite.length
            ? this.ventes_proximite
            : []),
        ]),
        ...own_parcelles,
      ])
      this.fitBoundsGeojsonInMap(collection)
      this.$emit('addMakers')

      this.addSourceLayersMultiGeojsonToMap(
        this.own_parcelles,
        this.green_parcelle_selected,
        this.source_layers_all_parcelle,
      )
    },
  },

  beforeDestroy() {
    this.markers_pc.forEach(function (marker) {
      marker.getPopup().remove()
      marker.remove()
    })
    this.markers_dvf.forEach(function (marker) {
      marker.getPopup().remove()
      marker.remove()
    })
    this.map.off('load', this.loadDataToMap)
  },
}
</script>
<style lang="scss">
.etudecomm-popup {
  top: -4px !important;
  z-index: 10;
  max-width: unset !important;
}

.marker-dvf {
  @apply border-[5px] bg-white rounded-full w-4 h-4 border-promy-blue-250;
}
.marker-pc {
  @apply border-[5px] bg-white rounded-full w-4 h-4 border-promy-orange-600;
}

.marker-dvf,
.marker-pc {
  &.active {
    width: 0 !important;
    height: 0 !important;
  }
}

[class*='position-icon-'] {
  @apply border-[9px]  bg-white w-6 h-6 rounded-full rounded-bl-none transform -rotate-45;
  margin-left: -9px;
  margin-top: -9px;
}
.position-icon-pc {
  @apply border-promy-orange-600;
}
.position-icon-dvf {
  @apply border-promy-blue-250;
}
</style>
