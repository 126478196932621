import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/map/mapbox'
import helpers from '@/mixins/helpers'

export default {
  mixins: [Mapbox, helpers],

  data() {
    return {
      typesDVFIcon: {
        Maison: 'maison-icon',
        Appartement: 'appartement-icon',
        Dépendance: 'default-building-icon',
        'Local industriel. commercial ou assimilé': 'industriel-icon',
        'N.C': 'default-building-icon',
      },
      typesPCIcon: {
        Commencé: 'travaux-icon',
        Autorisé: 'autorisation-icon',
        Terminé: 'travaux-icon',
        'N.C': 'default-building-icon',
      },
    }
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
      markers_pc: 'etudeCommerciale/get_markers_pc',
      markers_dvf: 'etudeCommerciale/get_markers_dvf',
      activated_accordion: 'etudeCommerciale/get_activated_accordion',
      dvf: 'address/dvf',
      permis_construire: 'address/permis_construire',
    }),
    checked_pc: {
      get() {
        return this.$store.getters['etudeCommerciale/get_checked_pc']
      },
      set(value) {
        this.$store.commit('etudeCommerciale/SET_CHECKED_PC', value)
      },
    },
    checked_dvf: {
      get() {
        return this.$store.getters['etudeCommerciale/get_checked_dvf']
      },
      set(value) {
        this.$store.commit('etudeCommerciale/SET_CHECKED_DVF', value)
      },
    },
    ventes_proximite_list() {
      return this.isExist(this.dvf) ? this.dvf : []
    },
    permis_construire_list() {
      return this.permis_construire
    },
    active_accordion: {
      get() {
        return this.$store.getters[
          'etudeCommerciale/get_activated_accordion_idx'
        ]
      },
      set(val) {
        this.$store.commit('etudeCommerciale/SET_ACTIVATED_ACCORDION_IDX', val)
      },
    },
  },
  watch: {
    checked_pc(value) {
      value ? this.addMarkersPC() : this.hideMarkers(this.markers_pc)
    },
    checked_dvf(value) {
      value ? this.addMarkersDVF() : this.hideMarkers(this.markers_dvf)
    },
  },
  methods: {
    hideMarkers(list_marker) {
      list_marker.forEach((marker) => {
        marker.remove()
      })
    },
    addMarker(props, coordinates, type) {
      const popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
        className: 'etudecomm-popup',
        closeButton: false,
      })

      let infos = {
        title:
          type === 'dvf'
            ? "Nombre de transaction dans l'adresse"
            : "Nombre de permis dans l'adresse",
        count: props.length,
        adresse: props[0].adresse,
        items: props,
      }

      let el = this.addIconMarkerEtudeCommerciale(props[0], type)
      let popup_marker = this.addCustomMarkerWithPopupInMap(
        this.createPopupEtudeCommerciale(type, infos),
        coordinates,
        el,
        popup,
      )

      popup_marker.popup.on('open', (e) => {
        let popupElement = document.querySelector(`#${type}-popup`, {
          once: true,
        })
        popupElement
      })
      let events = ['mouseenter', 'places']
      events.forEach((evt) => {
        el.addEventListener(evt, () => {
          popup_marker.popup.addTo(this.map)
        })
      })
      el.addEventListener('mouseleave', () => {
        popup_marker.popup.remove()
      })
      el.addEventListener('click', () => {
        let attr = el.getAttribute('id_etude')
        let type = attr.split('-')[0]
        let index = parseInt(attr.split('-')[1])
        this.activingAccordion(index, coordinates, type)
        popup_marker.popup.addTo(this.map)
        this.scrollToAccordion(index, type)
      })
      return popup_marker.marker
    },
    scrollToAccordion(index, type) {
      let scroll_el = document.querySelector(`#header_${type}_${index}`)

      if (scroll_el) {
        setTimeout(function () {
          scroll_el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 300)
      }
    },
    addIconMarkerEtudeCommerciale(data, type) {
      const el = document.createElement('div')
      el.className = `marker-${type}`
      el.style.width = `25px`
      el.style.height = `25px`
      el.setAttribute('id_etude', `${type}-${data.idx}`)

      return el
    },
    createPopupEtudeCommerciale(type, infos) {
      let colors = {
        base: type === 'dvf' ? 'promy-green-350 ' : 'promy-green-350',
        secondary: type === 'dvf' ? 'promy-yellow-20' : 'promy-emerald-20',
      }

      let template = `
          <div class='w-[300px] rounded-lg sm:w-50 sm-ws:w-68'> 
              <div class='flex text-sm justify-between items-center rounded-t-lg w-full bg-${colors.base} text-white font-extrabold px-3 py-2'>
                  <span class='text-xs md:text-xs'>${infos.title}</span>
                  <span>${infos.items.length}</span>
              </div>
              <div class='w-full bg-white text-sm md:text-xs leading-4 text-${colors.base} font-extrabold px-3 py-2 border-b border-promy-grey-20'>
                  ${infos.adresse}
              </div>`

      if (type === 'dvf') {
        template += `<div class="flex bg-white pt-1 w-full rounded-b-lg">
                          <div class="flex flex-col w-full text-promy-gray-600 font-medium">`
        infos.items.forEach((elm, idx) => {
          if (idx < 3) {
            template += `<div class="grid grid-cols-2 gap-2 w-full border-b px-3 py-2 text-promy-gray-600">
                                              <div class="flex space-x-1 ">
                                                  <div class="w-4 pt-1">
                                                  <img src="/images/GU/etudeCommerciale/${
                                                    this.typesDVFIcon[
                                                      this.checkInfo(
                                                        elm,
                                                        'type_local',
                                                      )
                                                    ]
                                                  }.svg" class="h-auto" />
                                                  </div>
                                                  <div class="flex flex-col flex-1">
                                                      <span class="font-bold">${this.checkInfo(
                                                        elm,
                                                        'surface_reelle_bati',
                                                      )} m²</span>
                                                      <span>${this.checkInfo(
                                                        elm,
                                                        'type_local',
                                                      )}</span>
                                                  </div>
                                              </div>
                                          <span class="text-promy-gray-600 font-medium text-right md:text-promy-xs">${this.checkInfo(
                                            elm,
                                            'date_mutation',
                                          )}</span>

                                          <div class="flex space-x-1 ">
                                          <div class="w-4 pt-1">
                                          <img
                                          src="/images/GU/etudeCommerciale/size-icon.svg"
                                          class="w-full"
                                        />
                                          </div>
                                          <div class="flex flex-col">
                                              <span class="font-bold">${this.checkInfo(
                                                elm,
                                                'surface_terrain',
                                              )} m²</span>
                                              <span>Sol</span>
                                          </div>
                                      </div>
                                      <div class="flex flex-col justify-end items-end">
                                          <span class="text-promy-gray-600 text-lg lg:text-sm leading-4 font-bold">${this.formatEuro(
                                            elm.valeur_fonciere,
                                          )}</span>
                                          <div class="flex flex-col text-right md:text-promy-xs">
                                          <span>${this.calculatePriceByM2(
                                            elm,
                                          )}</span>
                                          </div>
                                      </div>
                                  </div>`
          }
        })
      }

      if (type === 'pc') {
        template += `<div class="flex bg-white pt-1 w-full rounded-b-lg">
                          <div class="flex flex-col w-full text-promy-gray-600 font-medium">`
        infos.items.forEach((elm, idx) => {
          if (idx < 3) {
            template += `<div class="grid grid-cols-2 gap-2 w-full border-b px-3 py-2">
                                              <span class="col-span-2 text-promy-gray-600 font-bold">${this.checkInfo(
                                                elm,
                                                'date_reelle_autorisation',
                                              )}</span>
                                              <div class="flex flex-col justify-between space-y-1">
                                                  <div class="flex space-x-1">
                                                      <div class="w-4 pt-1">
                                                      <img src="/images/GU/etudeCommerciale/${
                                                        this.typesPCIcon[
                                                          this.checkInfo(
                                                            elm,
                                                            'type_evt',
                                                          )
                                                        ]
                                                      }.svg" class="h-auto" />
                                                      </div>
                                                      <div class="flex flex-col flex-1 capitalize">
                                                          <span>${this.checkInfo(
                                                            elm,
                                                            'type_evt',
                                                          )}</span>
                                                      </div>
                                                  </div>
                                                  <div class="flex space-x-1">
                                                      <div class="w-4 pt-1">
                                                      <img
                                                      src="/images/GU/etudeCommerciale/size-2-icon.svg"
                                                      class="w-full"
                                                    />
                                                      </div>
                                                      <div class="flex flex-col">
                                                          <span class="font-extrabold">${this.checkInfo(
                                                            elm,
                                                            'superficie_t',
                                                          )} m²</span>
                                                          <span>Sol</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          <div class="flex flex-col justify-between space-y-1">
                                              <div class="flex space-x-1">
                                                    <div class="w-4 pt-1">
                                                      <img
                                                        src="/images/GU/etudeCommerciale/size-icon.svg"
                                                        class="w-full"
                                                      />
                                                    </div>
                                                    <div class="flex flex-col">
                                                        <span style="font-size: 10px;">Surface du plancher</span>
                                                        <span class="font-extrabold">${this.checkInfo(
                                                          elm,
                                                          'sdp',
                                                        )} m²</span>
                                                    </div>
                                              </div>

                                              <div class="flex space-x-1">
                                                    <div class="w-4 pt-1">
                                                      <img
                                                        src="/images/GU/etudeCommerciale/pieces.svg"
                                                        class="w-full"
                                                      />
                                                    </div>
                                                    <div class="flex flex-col">
                                                      <span style="font-size: 10px;">Nombre de logement</span>
                                                      <span class="font-extrabold">${this.checkInfo(
                                                        elm,
                                                        'nombre_logements',
                                                      )}</span>
                                                    </div>
                                              </div>
                                              
                                              
                                          </div>
                                      </div>`
          }
        })
      }
      template += `
        ${
          infos.items.length > 3
            ? `<div class="w-full cursor-pointer bg-promy-gray-240 px-3 py-2 rounded-b-lg text-sm text-promy-green-200 font-bold" id="${type}-popup">Cliquez pour voir plus</div>`
            : ''
        }
        </div></div>`

      template += `</div>`
      return template
    },
    checkInfo(item, key) {
      return item[key] ? item[key] : 'N.C'
    },
    activingAccordion(idx, coordinates, type) {
      if (this.active_accordion != `${type}-${idx}`) {
        this.active_accordion = `${type}-${idx}`
        this.$store.commit('etudeCommerciale/SET_ACTIVATED_ACCORDION', type)
      } else {
        this.active_accordion = null
        this.$store.commit('etudeCommerciale/SET_ACTIVATED_ACCORDION', null)
      }

      this.flyToPosition(coordinates, type)
    },

    changeMarkerStyle(point, list_marker) {
      list_marker.map((marker) => {
        if (
          marker._lngLat.lat === point.geometry.coordinates[1] &&
          marker._lngLat.lng === point.geometry.coordinates[0] &&
          this.activated_accordion
        ) {
          var newElement = document.createElement('div')
          newElement.classList.add(`position-icon-${this.activated_accordion}`)
          marker._element.appendChild(newElement)
          marker._element.classList.add('active')
        } else {
          marker._element.classList.remove('active')
          marker._element.innerHTML = ''
        }
      })
    },

    flyToPosition(position, type) {
      let point = this.$turf.point([position[0], position[1]])
      this.fitBoundsPoint(point)

      this.changeMarkerStyle(point, this.markers_dvf)
      this.changeMarkerStyle(point, this.markers_pc)
    },

    addMarkersPC() {
      if (this.permis_construire_list && this.permis_construire_list.length) {
        this.permis_construire_list.forEach((element, idx) => {
          element[0]['idx'] = idx
          this.$store.commit(
            'etudeCommerciale/SET_MARKERS_PC',
            this.addMarker(
              element,
              [
                element[0].location.coordinates[1],
                element[0].location.coordinates[0],
              ],
              'pc',
            ),
          )
        })
      }
    },
    addMarkersDVF() {
      if (this.ventes_proximite_list && this.ventes_proximite_list.length) {
        this.ventes_proximite_list.forEach((element, idx) => {
          element[0]['idx'] = idx
          this.$store.commit(
            'etudeCommerciale/SET_MARKERS_DVF',
            this.addMarker(
              element,
              [
                element[0].location.coordinates[0],
                element[0].location.coordinates[1],
              ],
              'dvf',
            ),
          )
        })
      }
    },
    getAddressDvf(item) {
      return item.hasOwnProperty('adresse')
        ? item.adresse
        : [item.adresse_nom_voie, item.nom_commune, item.code_postal].join(' ')
    },
    getAdressPC(item) {
      return [item.adr_libellevoie_t, item.adr_cp_t, item.adr_localite_t].join(
        ' ',
      )
    },
    fitBoundsPoint(point) {
      var buffered = this.$turf.buffer(point, 0.04, {
        units: 'miles',
      })
      this.fitBoundsGeojsonInMap(buffered)
    },
  },
}
